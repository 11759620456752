import api from '../util/api';

export function getProfile() {
    return api.get('/api/v1/user/profile');
}

export function getNames() {
    return api.get('/api/v1/user/profile/names');
}

export function updateName(data) {
    return api.post('/api/v1/user/profile/name', data);
}

export function getPictures() {
    return api.get('/api/v1/user/profile/pictures');
}

export function updatePicture(data) {
    return api.post('/api/v1/user/profile/picture', data);
}

import React from 'react';
import {withRouter} from "react-router-dom";
import * as routes from "../constants/routes";
import selectRoute from "./selectRoute";
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import {getDecks, startBattleGame} from '../clients/join';
import {setAuthData} from "../util/auth";
import UserAvatarConnector from "./UserAvatarConnector";
import Typography from "@material-ui/core/Typography";

class JoinDecks extends React.Component {
    constructor(props) {
        super(props);

        this.handleDeckSelect = this.handleDeckSelect.bind(this);
        this.handleDeckCancel = this.handleDeckCancel.bind(this);
        this.state = {
            decks: null,
        };
    }

    componentDidMount() {
        getDecks().then((response) => {
            this.setState({decks: response.data});
        });
    }

    handleDeckSelect(deckHash) {
        if (this.props.location.pathname === routes.BATTLE_DECK) {
            startBattleGame({
                deck: deckHash
            }).then((response) => {
                setAuthData(response.data);
                this.props.history.push(routes.GAME_LODGE);
            }).catch(() => {
                this.props.history.push(routes.USER);
            });
        } else {
            this.props.history.push(routes.TRAINING_DECK + '/' + deckHash);
        }
    }

    handleDeckCancel() {
        this.props.history.push(routes.USER);
    }

    render() {
        let listItems = [];
        if (this.state.decks === null) {
            listItems.push(
                <CircularProgress key="loading" style={{marginLeft: '50%', left: '-20px', position: 'relative'}}/>
            );
        } else {
            for (let key in this.state.decks) {
                let deckHash = this.state.decks[key].id;
                let deckLogo = '/images/clan/' + this.state.decks[key].clan + '.png';
                listItems.push(
                    <Button
                        className="menu-button"
                        variant="contained"
                        key={deckHash}
                        color="primary"
                        onClick={() => this.handleDeckSelect(deckHash)}
                    >{this.state.decks[key].name}<img className="deck-clan-logo" src={deckLogo} alt={this.state.decks[key].clan}/>
                    </Button>
                );
            }
        }

        listItems.push(
            <Button
                variant="contained"
                className="menu-button"
                key="cancel"
                color="secondary"
                onClick={this.handleDeckCancel}
            >Cancel</Button>
        );
        return (
            <div className="menu-screen">
                <UserAvatarConnector/>
                <Typography color="textPrimary">Choose deck</Typography>
                {listItems}
            </div>
        );
    }
}

export default selectRoute(withRouter(JoinDecks));

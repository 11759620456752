import React from 'react';
import {withRouter} from "react-router-dom";
import * as routes from "../constants/routes";
import selectRoute from "./selectRoute";
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import {cancelGame, updateStatus} from '../clients/join';
import {clearGameData, getOpponentHash, setAuthData} from "../util/auth";
import {Typography} from "@material-ui/core";
import UserAvatarConnector from "./UserAvatarConnector";

class JoinWait extends React.Component {
    constructor(props) {
        super(props);

        this.handleCancel = this.handleCancel.bind(this);
        this.timer = this.timer.bind(this);
        this.state = {
            intervalId: null,
        };
    }

    componentDidMount() {
        this.setState({
            intervalId: setTimeout(this.timer, 1000),
        });
    }

    handleCancel() {
        cancelGame().then(() => {
            clearGameData();
            this.props.history.push(routes.USER);
        });
    }

    componentWillUnmount() {
        clearInterval(this.state.intervalId);
    }

    timer() {
        console.log('timer called');
        updateStatus().then((response) => {
            setAuthData(response.data);
            if (getOpponentHash()) {
                this.props.history.push(routes.GAME);
            } else {
                this.setState({
                    intervalId: setTimeout(this.timer, 1000),
                });
            }
        }).catch(() => {
            clearGameData();
            this.props.history.push(routes.USER);
        });
    }

    render() {
        let listItems = [];
        listItems.push(
            <Typography key="status" style={{color: 'white'}} variant="subtitle1">Waiting for opponent</Typography>
        );
        listItems.push(
            <CircularProgress key="waiting" style={{marginLeft: '50%', left: '-20px', position: 'relative'}}/>
        );

        listItems.push(
                <Button
                    variant="contained"
                    className="menu-button"
                    color="secondary"
                    key="cancel"
                    onClick={this.handleCancel}
                >Cancel</Button>
        );
        return (
            <div className="menu-screen">
                <UserAvatarConnector/>
                {listItems}
            </div>
        );
    }
}

export default selectRoute(withRouter(JoinWait));

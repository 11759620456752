import React from 'react';
import {withRouter} from "react-router-dom";
import * as routes from "../constants/routes";
import selectRoute from "./selectRoute";
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import UserAvatarConnector from "./UserAvatarConnector";
import {getTrainingBots, startTrainingGame} from "../clients/join";
import Typography from "@material-ui/core/Typography";
import {setAuthData} from "../util/auth";

class JoinBots extends React.Component {
    constructor(props) {
        super(props);

        let deckHash = null;
        if (props.match.params.deck !== undefined) {
            deckHash = props.match.params.deck;
        }

        this.handleCancel = this.handleCancel.bind(this);

        this.state = {
            bots: null,
            deckHash: deckHash,
        };
    }

    componentDidMount() {
        getTrainingBots().then((response) => {
            this.setState({bots: response.data.bots});
        });
    }

    handleCancel() {
        this.props.history.push(routes.USER);
    }

    handleSelect(name) {
        startTrainingGame({
            deck: this.state.deckHash,
            name: name,
        }).then((response) => {
            setAuthData(response.data);
            this.props.history.push(routes.GAME);
        }).catch(() => {
            this.props.history.push(routes.USER);
        })
    }

    render() {
        let listItems = [];
        listItems.push(<Typography key="label" color="textPrimary">Choose opponent</Typography>)
        if (this.state.bots === null) {
            listItems.push(
                <CircularProgress key="loading" style={{marginLeft: '50%', left: '-20px', position: 'relative'}}/>
            );
        } else {
            for (let key in this.state.bots) {
                let clanLogo = '/images/clan/' + this.state.bots[key].clan + '.png';
                listItems.push(
                    <Button
                        className="menu-button"
                        variant="contained"
                        key={key}
                        color="primary"
                        onClick={() => this.handleSelect(this.state.bots[key].name)}
                    >{this.state.bots[key].name}<img className="deck-clan-logo" src={clanLogo} alt={this.state.bots[key].clan}/>
                    </Button>
                );
            }
            listItems.push(
                <Button
                    className="menu-button"
                    variant="contained"
                    key="random"
                    color="primary"
                    onClick={() => this.handleSelect(null)}
                >Random</Button>
            );
        }
        listItems.push(
            <Button
                variant="contained"
                className="menu-button"
                key="cancel"
                color="secondary"
                onClick={this.handleCancel}
            >Cancel</Button>
        );

        return (
            <div className="menu-screen">
                <UserAvatarConnector/>
                {listItems}
            </div>
        );
    }
}

export default selectRoute(withRouter(JoinBots));

import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import CardView from "./CardView";
import {getUserHash} from "../../util/auth";
import {Tooltip} from "@material-ui/core";

class DropCircle extends React.Component {
    constructor(props) {
        super(props);

        this.handleDropView = this.handleDropView.bind(this);
        this.handleBindView = this.handleBindView.bind(this);
        this.handleCardMouseOver = this.handleCardMouseOver.bind(this);
    }

    render() {
        let cardParams = {...this.props.card};
        if (!cardParams.image) {
            cardParams.image = '/images/field/drop.png';
        }
        return (
            <div className="card-circle discard">
                <CardView {...cardParams} onMouseOver={this.handleCardMouseOver}/>
                <div className="count">{this.props.drop_count}</div>
                {this.props.drop_count > 0 && <Tooltip title="View drop zone cards" enterDelay={0}>
                    <div className="view" onClick={this.handleDropView}><i className="fas fa-monument"/></div>
                </Tooltip>}
                {this.props.bind_count > 0 && <Tooltip title="View bind zone cards" enterDelay={0}>
                    <div className="bind" onClick={this.handleBindView}><i className="fas fa-link"></i></div>
                </Tooltip>}
            </div>
        )
    }

    handleCardMouseOver() {
        if (this.props.card) {
            this.props.dispatch({
                'type': 'card.preview',
                'data': {
                    'image': this.props.card.image,
                    'name': this.props.card.name,
                    'description': this.props.card.description,
                },
            });
        }
    }

    handleDropView() {
        if (Object.keys(this.props.dialog.cards).length === 0) {
            let newActions = [];
            let newTitle = this.props.dialog.title;
            if (Object.keys(this.props.dialog.actions).length > 0) {
                newActions = {close: 'Close drop cards', ...this.props.dialog.actions};
            } else {
                newActions = {close: 'Close drop cards'};
                newTitle = 'Drop zone cards';
            }

            this.props.dispatch({
                type: 'game.updates',
                data: [
                    {
                        type: 'action.dialog',
                        player: getUserHash(),
                        data: {
                            load_zone: 'drop',
                            load_player_hash: this.props.hash,
                            title: newTitle,
                            actions: newActions,
                        }
                    }
                ]
            });
        }
    }

    handleBindView() {
        if (Object.keys(this.props.dialog.cards).length === 0) {
            let newActions = [];
            let newTitle = this.props.dialog.title;
            if (Object.keys(this.props.dialog.actions).length > 0) {
                newActions = {close: 'Close bind cards', ...this.props.dialog.actions};
            } else {
                newActions = {close: 'Close bind cards'};
                newTitle = 'Bind zone cards';
            }

            this.props.dispatch({
                type: 'game.updates',
                data: [
                    {
                        type: 'action.dialog',
                        player: getUserHash(),
                        data: {
                            load_zone: 'bind',
                            load_player_hash: this.props.hash,
                            title: newTitle,
                            actions: newActions,
                        }
                    }
                ]
            });
        }
    }
}

DropCircle.propTypes = {
    hash: PropTypes.string.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        drop_count: state['player_' + ownProps.hash + '_drop_count'] ? state['player_' + ownProps.hash + '_drop_count'] : 0,
        bind_count: state['player_' + ownProps.hash + '_bind_count'] ? state['player_' + ownProps.hash + '_bind_count'] : 0,
        card: state['player_' + ownProps.hash + '_drop_card'] ? state['player_' + ownProps.hash + '_drop_card'] : null,
        dialog: state.dialog ? state.dialog : [],
    };
}

export default connect(mapStateToProps)(DropCircle);

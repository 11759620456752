export const LOGIN = '/';
export const USER = '/user';
export const USER_PICTURES = '/user/pictures';
export const USER_NAMES = '/user/names';
export const USER_DECKS = '/user/decks';
export const USER_BUILDER = '/user/builder';
export const GAME = '/game';
export const GAME_LODGE = '/game/wait';
export const BATTLE_DECK = '/battle';
export const TRAINING_DECK =  '/training';
export const WATCH_LIST = '/games';

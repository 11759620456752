import api from '../util/api';

export function getDecks() {
    return api.get('/api/v1/user/decks');
}

export function getDeck(id) {
    return api.get('/api/v1/user/decks/' + id);
}

export function updateDeck(id, data) {
    return api.post('/api/v1/user/decks' + (id ? '/' + id : ''), data);
}

export function deleteDeck(id) {
    return api.delete('/api/v1/user/decks/' + id);
}

import api from '../util/api';

export function sendGoogleToken(token) {
    return api.post('/login/google', token);
}

export function sendFacebookToken(token) {
    return api.post('/login/facebook', token);
}

export function sendDummyToken() {
    return api.post('/login/dummy');
}

export function sendDummy2Token() {
    return api.post('/login/dummy2');
}

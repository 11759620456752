import api from '../util/api';

export function startBattleGame(data) {
    return api.post('/api/v1/join/battle', data);
}

export function startTrainingGame(data) {
    return api.post('/api/v1/join/training', data);
}

export function getDecks() {
    return api.get('/api/v1/join/deck');
}

export function getActiveGames() {
    return api.get('/api/v1/join/games');
}

export function getTrainingBots() {
    return api.get('/api/v1/join/training/bots');
}

export function selectDeck(data) {
    return api.post('/api/v1/join/deck', data);
}

export function cancelGame() {
    return api.post('/api/v1/join/cancel');
}

export function updateStatus() {
    return api.get('/api/v1/join/wait');
}

import React from 'react';
import {connect} from "react-redux";
import CardView from './CardView';
import PropTypes from "prop-types";

class DeckCircle extends React.Component {
    render() {
        return (
            <div className="card-circle deck">
                <CardView image="/images/field/deck.png"/>
                <div className="count">{this.props.count}</div>
            </div>
        )
    }
}

DeckCircle.propTypes = {
    hash: PropTypes.string.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        count: state['player_' + ownProps.hash + '_deck_count'] ? state['player_' + ownProps.hash + '_deck_count'] : 0,
    };
}

export default connect(mapStateToProps)(DeckCircle);

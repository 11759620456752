import React from 'react';
import {connect} from "react-redux";

class CardPreview extends React.Component {
    render() {
        let description = null;
        let image = null;
        let name = null;

        if (this.props.preview) {
            image = <img className="image" src={this.props.preview.image} alt="Card preview"/>;
            name = <div className="name">{this.props.preview.name}</div>;
            if (this.props.preview.description) {
                let descriptionText = this.props.preview.description;
                descriptionText = descriptionText.replace(/\n/g, '<br/>');
                // ability zone
                descriptionText = descriptionText.replace(/\]\(VC\)/gi, ']<i class="zone-open"></i><i class="ability-icon vanguard-icon"></i><i class="zone-close"></i>');
                descriptionText = descriptionText.replace(/\]\(GC\)/gi, ']<i class="zone-open"></i><i class="ability-icon guardian-icon"></i><i class="zone-close"></i>');
                descriptionText = descriptionText.replace(/\]\(RC\)/gi, ']<i class="zone-open"></i><i class="ability-icon rearguard-icon"></i><i class="zone-close"></i>');
                descriptionText = descriptionText.replace(/\]\(VC\/RC\)/gi, ']<i class="zone-open"></i><i class="ability-icon vanguard-icon"></i><i class="ability-icon separator-icon"></i><i class="ability-icon rearguard-icon"></i><i class="zone-close"></i>');
                descriptionText = descriptionText.replace(/\]\(RC\/GC\)/gi, ']<i class="zone-open"></i><i class="ability-icon rearguard-icon"></i><i class="ability-icon separator-icon"></i><i class="ability-icon guardian-icon"></i><i class="zone-close"></i>');
                // text zone
                descriptionText = descriptionText.replace(/\(GC\)/gi, '<i class="guardian-icon"></i>');
                descriptionText = descriptionText.replace(/\(VC\)/gi, '<i class="vanguard-icon"></i>');
                descriptionText = descriptionText.replace(/\(RC\)/gi, '<i class="rearguard-icon"></i>');
                // misc
                descriptionText = descriptionText.replace(/\[Power\]/gi, '<i class="power-icon"></i>');
                descriptionText = descriptionText.replace(/\[Critical\]/gi, '<i class="critical-icon"></i>');
                descriptionText = descriptionText.replace(/\[AUTO\]/gi, '<i class="ability-auto"></i>');
                descriptionText = descriptionText.replace(/\[ACT\]/gi, '<i class="ability-act"></i>');
                descriptionText = descriptionText.replace(/\[CONT\]/gi, '<i class="ability-cont"></i>');
                descriptionText = descriptionText.replace(/\[1\/Turn\]/gi, '<i class="one-turn-icon"></i>');
                descriptionText = descriptionText.replace(/COST /g, '<i class="cost-icon"></i>');
                descriptionText = descriptionText.replace(/\[Rest\]/g, '<i class="rest-icon"></i>');
                descriptionText = descriptionText.replace(/\[Stand\]/g, '<i class="stand-icon"></i>');
                descriptionText = descriptionText.replace(/Soul Blast \(([1-5])\)/g, '<i class="soul-blast-$1-icon"></i>');
                descriptionText = descriptionText.replace(/Soul Charge \(([1-2])\)/g, '<i class="soul-charge-$1-icon"></i>');
                descriptionText = descriptionText.replace(/Counter Blast \(([1-2])\)/g, '<i class="counter-blast-$1-icon"></i>');
                descriptionText = descriptionText.replace(/Counter Charge \(([1-2])\)/g, '<i class="counter-charge-$1-icon"></i>');
                // descriptionText = descriptionText.replace("[Power]", '<i class="ability-icon power"/>', 'gi');

                // descriptionText = descriptionText.replace(/\[AUTO\]/gi, '<i class="ability-sprite auto"></i>');
                // console.log(descriptionText);
                description = (<div className="description" dangerouslySetInnerHTML={{__html: descriptionText}}/>);
            }
        }
        return <div className="card-preview" >
            {image}
            {description}
            {name}
        </div>;

    }
}

function mapStateToProps(state) {
    return {preview: state.preview};
}

export default connect(mapStateToProps)(CardPreview)

/*

                    e = e.replace(/\[AUTO\]/gi, '<i class="ability-sprite auto"></i>'),
                    e = e.replace(/\[ACT\]/gi, '<i class="ability-sprite act"></i>'),
                    e = e.replace(/\[CONT\]/gi, '<i class="ability-sprite cont"></i>'),
                    e = e.replace(/\(VC\/RC\)/gi, '<i class="ability-sprite vanguard"></i>/<i class="ability-sprite rearguard"></i>'),
                    e = e.replace(/\(VC\)/gi, '<i class="ability-sprite vanguard"></i>'),
                    e = e.replace(/\(RC\)/gi, '<i class="ability-sprite rearguard"></i>'),
                    e = e.replace(/\(GC\)/gi, '<i class="ability-sprite guard"></i>'),

e = e.replace(/Counter-Blast 1/gi, 'Counter-Blast <span class="round-num">&#9312;</span>'),
e = e.replace(/Counter-Blast 2/gi, 'Counter-Blast <span class="round-num">&#9313;</span>'),
e = e.replace(/Counter-Blast 3/gi, 'Counter-Blast <span class="round-num">&#9314;</span>'),
e = e.replace(/Counter-Blast 4/gi, 'Counter-Blast <span class="round-num">&#9315;</span>'),
e = e.replace(/Counter-Blast 5/gi, 'Counter-Blast <span class="round-num">&#9316;</span>'),
e = e.replace(/Counter-Blast/gi, '<i class="ability-sprite counterblast"></i>'),
e = e.replace(/Counter-Charge 1/gi, 'Counter-Charge <span class="round-num">&#9312;</span>'),
e = e.replace(/Counter-Charge 2/gi, 'Counter-Charge <span class="round-num">&#9313;</span>'),
e = e.replace(/Counter-Charge 3/gi, 'Counter-Charge <span class="round-num">&#9314;</span>'),
e = e.replace(/Counter-Charge 4/gi, 'Counter-Charge <span class="round-num">&#9315;</span>'),
e = e.replace(/Counter-Charge 5/gi, 'Counter-Charge <span class="round-num">&#9316;</span>'),
e = e.replace(/Counter-Charge/gi, '<i class="ability-sprite countercharge"></i>'),
e = e.replace(/Soul-Blast 1/gi, 'Soul-Blast <span class="round-num">&#9312;</span>'),
e = e.replace(/Soul-Blast 2/gi, 'Soul-Blast <span class="round-num">&#9313;</span>'),
e = e.replace(/Soul-Blast 3/gi, 'Soul-Blast <span class="round-num">&#9314;</span>'),
e = e.replace(/Soul-Blast 4/gi, 'Soul-Blast <span class="round-num">&#9315;</span>'),
e = e.replace(/Soul-Blast 5/gi, 'Soul-Blast <span class="round-num">&#9316;</span>'),
e = e.replace(/Soul-Blast/gi, '<i class="ability-sprite soulblast"></i>'),
e = e.replace(/Soul-Charge 1/gi, 'Soul-Charge <span class="round-num">&#9312;</span>'),
e = e.replace(/Soul-Charge 2/gi, 'Soul-Charge <span class="round-num">&#9313;</span>'),
e = e.replace(/Soul-Charge 3/gi, 'Soul-Charge <span class="round-num">&#9314;</span>'),
e = e.replace(/Soul-Charge 4/gi, 'Soul-Charge <span class="round-num">&#9315;</span>'),
e = e.replace(/Soul-Charge 5/gi, 'Soul-Charge <span class="round-num">&#9316;</span>'),
e = e.replace(/Soul-Charge/gi, '<i class="ability-sprite soulcharge"></i>'),
e = e.replace(/\[Rest\]/gi, '<i class="ability-sprite rest"></i>'),
e = e.replace(/\[Stand\]/gi, '<i class="ability-sprite stand"></i>'),
e = e.replace(/\[Boost\]/gi, '<i class="ability-sprite boost"></i>'),
e = e.replace(/\[Intercept\]/gi, '<i class="ability-sprite intercept"></i>'),
e = e.replace(/\[Twin Drive!!\]/gi, '<i class="ability-sprite twindrive"></i>'),
e = e.replace(/\[Power\]/gi, '<i class="ability-sprite power"></i>'),
e = e.replace(/\[Critical\]/gi, '<i class="ability-sprite critical"></i>'),
e = e.replace(/\[Turn\/1\]/gi, '<i class="ability-sprite once-turn"></i>'),
e = e.replace(/\[1\/Turn\]/gi, '<i class="ability-sprite once-turn"></i>'),
e = e.replace(/\T1@/gi, '<i class="ability-sprite once-turn"></i>'),
e = e.replace(/\[Stride\]/gi, '<i class="ability-sprite stride"></i>'),
e = e.replace(/\[Legion\]/gi, '<i class="ability-sprite legion"></i>'),
e = e.replace(/\[Legion 20000\]/gi, '<i class="ability-sprite legion-20k"></i>'),
e = e.replace(/\[Legion 21000\]/gi, '<i class="ability-sprite legion-21k"></i>'),
e = e.replace(/\[Legion 22000\]/gi, '<i class="ability-sprite legion-22k"></i>'),
e = e.replace(/Limit-Break 4/gi, '<i class="ability-sprite limit-break-4"></i>'),
e = e.replace(/LB@4/gi, '<i class="ability-sprite limit-break-4"></i>'),
e = e.replace(/Limit-Break 5/gi, '<i class="ability-sprite limit-break-5"></i>'),
e = e.replace(/LB@5/gi, '<i class="ability-sprite limit-break-5"></i>'),
e = e.replace(/LB@/gi, '<i class="ability-sprite limit-break"></i>'),
e = e.replace(/Generation Break 1/gi, '<i class="ability-sprite generation-break-1"></i>'),
e = e.replace(/Generation Break 2/gi, '<i class="ability-sprite generation-break-2"></i>'),
e = e.replace(/Generation Break 3/gi, '<i class="ability-sprite generation-break-3"></i>'),
e = e.replace(/GB@1/gi, '<i class="ability-sprite generation-break-1"></i>'),
e = e.replace(/GB@2/gi, '<i class="ability-sprite generation-break-2"></i>'),
e = e.replace(/GB@3/gi, '<i class="ability-sprite generation-break-3"></i>'),
e = e.replace(/\[G Guardian\]/gi, '<i class="ability-sprite gguardian"></i>')
 */

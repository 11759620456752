import React from 'react';
import {connect} from "react-redux";
import CardLink from "./CardLink";
import Typography from "@material-ui/core/Typography";

class ChatLog extends React.PureComponent {
    constructor(props) {
        super(props);

        this.handleLinkMouseOver = this.handleLinkMouseOver.bind(this);
    }

    scrollToBottom = () => {
        this.messagesEnd.scrollIntoView({ behavior: "smooth" });
    };

    componentDidMount() {
        this.scrollToBottom();
    };

    componentDidUpdate() {
        this.scrollToBottom();
    };

    handleLinkMouseOver(name, image, description) {
        this.props.dispatch({
            'type': 'card.preview',
            'data': {
                'image': image,
                'name': name,
                'description': description,
            },
        });
    }

    render() {
        let lines = [];
        let re = /\[\{(.*?)},\{(.*?)},\{(.*?)}]/ms;

        for (let key in this.props.log) {
            let parts = [];
            let remaining = this.props.log[key];
            let m = remaining.match(re);

            let maxDepth = 0;
            while (m) {
                maxDepth++;
                if (maxDepth > 3) {
                    console.log('something went wrong');
                    break;
                }
                parts.push(remaining.substr(0, m.index));
                // console.log('Generating link', m);
                parts.push(<CardLink key={m[1]} name={m[1]} image={m[2]} description={m[3]} onMouseOver={this.handleLinkMouseOver}/>);
                remaining = remaining.substr(m.index + m[0].length);
                m = remaining.match(re);
            }
            if (remaining.length > 0) {
                parts.push(remaining);
            }
            // do {
            //     m = remaining.match(/Se(.*?)ou/);
            //     console.log(m);
            //     if (m) {
            //         parts.push(remaining.substr(0, m.index));
            //         parts.push(<CardLink name="test" image="aaaa" description="desc"/>);
            //         remaining = remaining.substring(m.index + m[0].length);
            //     }
            //     // break;
            // } while (m);
            // parts.push(remaining);

            // let line = this.props.log[key];
            // console.log(line);
            // line = line.replace('Aichi', this.getCardLink());
            lines.push(<Typography  variant="body2" key={key}>{parts}</Typography>);
        }
        return (
            <div className="chat-log">
                {lines}
                <div key="end"
                     ref={(el) => { this.messagesEnd = el; }}>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        log: state.chat_log
    };
}

export default connect(mapStateToProps)(ChatLog);

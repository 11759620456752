import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import CardView from "./CardView";

class TriggerZone extends React.Component {
    constructor(props) {
        super(props);

        this.handleCardMouseOver = this.handleCardMouseOver.bind(this);
    }

    handleCardMouseOver() {
        if (this.props.card) {
            this.props.dispatch({
                'type': 'card.preview',
                'data': {
                    'image': this.props.card.image,
                    'name': this.props.card.name,
                    'description': this.props.card.description,
                },
            });
        }
    }

    render() {
        let cardParams = {...this.props.card};

        return (
            <div className="trigger">
                <CardView {...cardParams} onMouseOver={this.handleCardMouseOver}/>
            </div>
        );
    }
}

TriggerZone.propTypes = {
    hash: PropTypes.string.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        card: state['player_' + ownProps.hash + '_trigger'] ? state['player_' + ownProps.hash + '_trigger'] : null,
    };
}

export default connect(mapStateToProps)(TriggerZone);

import {getPlayerHash, getUserHash} from './auth';

const initialField = {
    rearguard1: null,
    rearguard2: null,
    rearguard3: null,
    rearguard4: null,
    rearguard5: null,
    vanguard: null,
};

const initialDialog = {
    load_zone: null, // which zone cards to fetch: soul/drop/vanguard/action
    load_player_hash: null, // which players drop/soul view
    load_field_location: null, // which rearguard to view, used for equip gauge view
    title: null, // Please select your vanguards
    type: null, // vanguard, drop, soul, gauge, action, view
    cards: [], // all cards to choose from
    actions: [], // top => 'Top', bottom => 'Bottom', 'skip' => 'Ignore asd'  ... simple drop view will have only one 'close' => 'Ok'
    options: [], // minCount, maxCount, minGrade, etc.. same options as ability parts
};

const initialState = {
    chat_log: [],
    players: {},
    dialog: {...initialDialog},
    guardian_cards: [],
    selection: [], // currently selected card id or locations
    preview: null,
    error: null,
    finished: false,
};

function processGameUpdate(state, action) {
    if (
        action.player &&
        !state['player_' + action.player]
    ) {
         state = {
             ...state,
             ['player_' + action.player]: action.player,
             ['player_' + action.player + '_name']: null,
             ['player_' + action.player + '_avatar']: null,
             ['player_' + action.player + '_hand_update']: false,
             ['player_' + action.player + '_hand_cards']: null,
             ['player_' + action.player + '_damage_cards']: null,
             ['player_' + action.player + '_deck_count']: null,
             ['player_' + action.player + '_soul_count']: null,
             ['player_' + action.player + '_bind_count']: null,
             ['player_' + action.player + '_drop_count']: null,
             ['player_' + action.player + '_drop_card']: null,
             ['player_' + action.player + '_field']: {...initialField},
         };
    }

    switch (action.type) {
        case 'game.finished':
            return {
                ...state,
                finished: action.data,
                dialog: {...initialDialog},
                selection: [],
            };
        case 'chat.log':
            return {
                ...state,
                chat_log: [...state.chat_log, ...action.data]
            };
        case 'player.name':
            return {
                ...state,
                ['player_' + action.player + '_name']: action.data
            };
        case 'player.avatar':
            return {
                ...state,
                ['player_' + action.player + '_avatar']: action.data
            };
        case 'player.error':
            if (getPlayerHash() === action.player) {
                return {
                    ...state,
                    error: action.data,
                    selection: [],
                };
            }
            break;
        case 'damage.cards':
            return {
                ...state,
                ['player_' + action.player + '_damage_cards']: action.data
            };
        case 'hand.update':
            return {
                ...state,
                ['player_' + action.player + '_hand_update']: action.data
            };
        case 'hand.cards':
            return {
                ...state,
                ['player_' + action.player + '_hand_cards']: action.data
            };
        case 'deck.count':
            return {
                ...state,
                ['player_' + action.player + '_deck_count']: action.data
            };
        case 'soul.count':
            return {
                ...state,
                ['player_' + action.player + '_soul_count']: action.data
            };
        case 'bind.count':
            return {
                ...state,
                ['player_' + action.player + '_bind_count']: action.data
            };
        case 'drop.count':
            return {
                ...state,
                ['player_' + action.player + '_drop_count']: action.data
            };
        case 'drop.card':
            return {
                ...state,
                ['player_' + action.player + '_drop_card']: action.data
            };
        case 'card.trigger':
            return {
                ...state,
                ['player_' + action.player + '_trigger']: action.data
            };
        case 'card.vanguard':
            return {
                ...state,
                ['player_' + action.player + '_field']: {
                    ...state['player_' + action.player + '_field'],
                    vanguard: action.data
                }
            };
        case 'card.guardians':
             return {
                 ...state,
                 guardian_cards: action.data
             };
        case 'action.dialog':
            if (
                getUserHash() === action.player
                || (
                    !action.player
                    && state.dialog.type !== 'view'
                )
            ) {
                return {
                    ...state,
                    dialog: {
                        ...state.dialog,
                        ...action.data
                    }
                };
            }
            break;
        case 'action.clear':
            if (getPlayerHash() === action.player) {
                return {
                    ...state,
                    dialog: {...initialDialog},
                    selection: [],
                    error: null,
                };
            }
            break;
    //     case 'game.win':
    //         state.win = action.player;
    //         break;
        default:
            console.log('unknown game update', action);
    }

    // support for accel gift
    if (action.type.substring(0, 14) === 'card.rearguard') {
        return {
            ...state,
            ['player_' + action.player + '_field']: {
                ...state['player_' + action.player + '_field'],
                [action.type.substring(5)]: action.data
            }
        };
    }

    return state;
}

function processGameClick(state, action) {
    if (!state.dialog.type) {
        return state;
    }

    let id = (action.player ? action.player + '.' : '') + action.data;
    let index = state.selection.indexOf(id);
    if (index === -1) {
        state = {
            ...state,
            selection: [
                ...state.selection,
                id
            ]
        };
        if (state.dialog.options.max_count != undefined && state.selection.length > state.dialog.options.max_count) {
            state.selection.shift();
        }
    } else {
        return {
            ...state,
            selection: [
                ...state.selection.slice(0, index),
                ...state.selection.slice(index + 1)
            ]
        }
    }

    return state;
}

function mainAction(state = initialState, action) {
    let oldState = state;
    if (action.type !== 'card.preview') {
        console.log('Action received', action);
    }
    switch (action.type) {
        case 'card.preview':
            if (
                action.data === null
                || (
                    action.data.image
                    && action.data.image.charAt(0) !== '/'
                )
            ) {
                state = {
                    ...state,
                    preview: action.data
                };
            }
            break;
        case 'game.updates':
            if (Object.keys(action.data).length > 0) {
                for (let update of action.data) {
                    state = processGameUpdate(state, update);
                }
            }
            break;
        // case 'game.update':
        //     for (let update of action.updates) {
        //         state = processGameUpdate(state, update);
        //     }
        //     break;
        case 'game.clear':
            state = initialState;
            break;
        case 'game.click':
            state = processGameClick(state, action);
            break;
        default:
            state = processGameUpdate(state, action);
    }
    if (action.type !== 'card.preview' && oldState !== state) {
        console.log('state changed', state);
    }

    return state;
}

export default mainAction;

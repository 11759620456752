const USER_TOKEN = 'user.token';
const USER_NAME = 'user.name';
const USER_PICTURE = 'user.picture';
const GAME_HASH = 'game.hash';
const USER_HASH = 'user.hash';
const PLAYER_HASH = 'player.hash';
const OPPONENT_HASH = 'opponent.hash';

export function clearAuthData() {
    window.sessionStorage.removeItem(USER_TOKEN);
    window.sessionStorage.removeItem(USER_NAME);
    window.sessionStorage.removeItem(USER_PICTURE);
    window.sessionStorage.removeItem(GAME_HASH);
    window.sessionStorage.removeItem(USER_HASH);
    window.sessionStorage.removeItem(PLAYER_HASH);
    window.sessionStorage.removeItem(OPPONENT_HASH);
}

export function clearGameData() {
    window.sessionStorage.removeItem(GAME_HASH);
    window.sessionStorage.removeItem(PLAYER_HASH);
    window.sessionStorage.removeItem(OPPONENT_HASH);
}

export function setAuthData(data) {
    if (data.hasOwnProperty('user_token')) {
        window.sessionStorage.setItem(USER_TOKEN, data.user_token);
    }
    if (data.hasOwnProperty('user_name')) {
        window.sessionStorage.setItem(USER_NAME, data.user_name);
    }
    if (data.hasOwnProperty('user_picture')) {
        window.sessionStorage.setItem(USER_PICTURE, data.user_picture);
    }
    if (data.hasOwnProperty('game_hash')) {
        window.sessionStorage.setItem(GAME_HASH, data.game_hash);
    }
    if (data.hasOwnProperty('user_hash')) {
        window.sessionStorage.setItem(USER_HASH, data.user_hash);
    }
    if (data.hasOwnProperty('player_hash')) {
        window.sessionStorage.setItem(PLAYER_HASH, data.player_hash);
    }
    if (data.hasOwnProperty('opponent_hash')) {
        window.sessionStorage.setItem(OPPONENT_HASH, data.opponent_hash);
    }
}

export function getUserToken() {
    return window.sessionStorage.getItem(USER_TOKEN);
}

export function getUserName() {
    return window.sessionStorage.getItem(USER_NAME);
}

export function getUserPicture() {
    return window.sessionStorage.getItem(USER_PICTURE);
}

export function getGameHash() {
    return window.sessionStorage.getItem(GAME_HASH);
}

export function getUserHash() {
    return window.sessionStorage.getItem(USER_HASH);
}

export function getPlayerHash() {
    return window.sessionStorage.getItem(PLAYER_HASH);
}

export function getOpponentHash() {
    return window.sessionStorage.getItem(OPPONENT_HASH);
}

import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import CardView from "./CardView";

class DamageZone extends React.Component {
    constructor(props) {
        super(props);

        this.handleCardMouseOver = this.handleCardMouseOver.bind(this);
        this.handleCardClick = this.handleCardClick.bind(this);
    }

    handleCardMouseOver(id) {
        if (this.props.cards[id]) {
            this.props.dispatch({
                'type': 'card.preview',
                'data': {
                    'image': this.props.cards[id].image,
                    'name': this.props.cards[id].name,
                    'description': this.props.cards[id].description,
                },
            });
        }
    }

    handleCardClick(id) {
        this.props.dispatch({
            type: 'game.click',
            player: this.props.hash,
            data: id,
        });
    }

    render() {
        let cards = [];
        for (let key in this.props.cards) {
            let params = {...this.props.cards[key]};
            params['selected'] = this.props.selection.indexOf(this.props.hash + '.' + key) !== -1;
            cards.push(
                <div key={key} className="damage-card">
                    <CardView
                        key={key}
                        {...params}
                        onMouseOver={() => this.handleCardMouseOver(key)}
                        onClick={() => this.handleCardClick(key)}
                    />
                </div>
            );
        }
        return (
            <div className="damage-zone">
                {cards}
            </div>
        );
    }
}

DamageZone.propTypes = {
    hash: PropTypes.string.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        cards: state['player_' + ownProps.hash + '_damage_cards'] ? state['player_' + ownProps.hash + '_damage_cards'] : [],
        selection: state.selection,
    };
}

export default connect(mapStateToProps)(DamageZone);

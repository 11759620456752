import React from 'react';
import * as routes from '../constants/routes';
import {getPictures, updatePicture} from "../clients/profile";
import selectRoute from "./selectRoute";
import { withRouter } from 'react-router-dom';
import Button from "@material-ui/core/Button";
import {getUserPicture, setAuthData} from "../util/auth";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import UserAvatar from "./UserAvatar";
import UserAvatarConnector from "./UserAvatarConnector";
import Typography from "@material-ui/core/Typography";

class UserPictureSelection extends React.Component {
    constructor(props) {
        super(props);

        this.handleBack = this.handleBack.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.state = {
            picture: null,
            pictures: [],
        };
    }

    componentDidMount() {
        getPictures().then((response) => {
            this.setState({
                picture: getUserPicture(),
                pictures: response.data.pictures
            });
        });
    }

    handleChange(event) {
        this.setState({picture: event.target.value});
    }

    handleUpdate() {
        updatePicture({
            picture: this.state.picture,
        }).then((response) => {
            setAuthData(response.data);
            this.props.history.push(routes.USER);
        });
    }

    handleBack() {
        this.props.history.push(routes.USER);
    }

    render() {
        let pictures = [];

        for (let key in this.state.pictures) {
            pictures.push(
                <FormControlLabel key={key} value={this.state.pictures[key]} control={<Radio />} label={<UserAvatar picture={this.state.pictures[key]} key={key}/>} />
            );
        }

        return (
            <div className="display-inner-container">
                <div className="menu-screen">
                    <UserAvatarConnector/>
                    <Typography color="textPrimary">Choose new avatar</Typography>
                    <FormControl component="fieldset" className="user-picture-container">
                        <RadioGroup name="user-picture" value={this.state.picture} onChange={this.handleChange}>
                            {pictures}
                        </RadioGroup>
                    </FormControl>
                    <Button
                        variant="contained"
                        className="menu-button"
                        key="change"
                        color="primary"
                        onClick={this.handleUpdate}
                    >Change</Button>
                    <Button
                        variant="contained"
                        className="menu-button"
                        key="back"
                        color="secondary"
                        onClick={this.handleBack}
                    >Back</Button>
                </div>
            </div>
        )
    }
}

export default selectRoute(withRouter(UserPictureSelection));

import React from 'react';
import {connect} from "react-redux";
import CardView from "./CardView";

class GuardianZone extends React.Component {
    constructor(props) {
        super(props);

        this.handleCardMouseOver = this.handleCardMouseOver.bind(this);
    }

    handleCardMouseOver(key) {
        if (this.props.cards[key]) {
            this.props.dispatch({
                'type': 'card.preview',
                'data': {
                    'image': this.props.cards[key].image,
                    'name': this.props.cards[key].name,
                    'description': this.props.cards[key].description,
                },
            });
        }
    }

    render() {
        // let guardians = [];
        let cards = [];
        for (let key in this.props.cards) {
            cards.push(
                <div key={key} className="guardian">
                    <CardView
                        key={key}
                        // onClick={() => this.handleLocationClick(key)}
                        onMouseOver={() => this.handleCardMouseOver(key)}
                        {...this.props.cards[key]}
                    />
                </div>);

        }

        return (
            <div className="guardian-zone">
                {cards}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        cards: state['guardian_cards'] ? state['guardian_cards'] : [],
    };
}

export default connect(mapStateToProps)(GuardianZone);

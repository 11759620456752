import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import React, { Component } from 'react';
import {
    BrowserRouter as Router,
    Route,
} from 'react-router-dom';
import Login from './Login';
import GamePage from './Game';
import UserMenu from './UserMenu';
import * as routes from '../constants/routes';
import UserDecks from "./UserDecks";
import UserBuilder from "./UserBuilder";
import UserPictureSelection from "./UserPictureSelection";
import UserNameSelection from "./UserNameSelection";
import JoinDecks from "./JoinDecks";
import JoinWait from "./JoinLobby";
import JoinBots from "./JoinBots";
import JoinGames from "./JoinGames";

const theme = createMuiTheme({
    palette: {
        type: 'dark',
        // text: {
        //     primary: '#FFFFFF',
        // },
        // background: {
        //     default: '#000000',
        // }
    },
    overrides: {
        MuiInputLabel: {
            root: {
                '&$focused': {
                    color: 'white'
                }
            }
        },
        // MuiSnackbarContent: {
        //     root: {
        //         color: 'white',
        //         backgroundColor: '#424242'
        //     }
        // }
    }
});

class App extends Component {
  render() {
    return (
        <MuiThemeProvider theme={theme}>
            <Router basename="/">
                <div className="display">
                    <Route
                        exact path={routes.LOGIN}
                        component={() => <Login/>}
                    />
                    <Route
                        exact path={routes.GAME}
                        component={() => <GamePage />}
                    />
                    <Route
                        exact path={routes.USER_DECKS}
                        component={() => <UserDecks/>}
                    />
                    <Route
                        exact path={routes.USER_BUILDER + '/:deck'}
                        component={() => <UserBuilder/>}
                    />
                    <Route
                        exact path={routes.USER_BUILDER}
                        component={() => <UserBuilder/>}
                    />
                    <Route
                        exact path={routes.USER_PICTURES}
                        component={() => <UserPictureSelection/>}
                    />
                    <Route
                        exact path={routes.USER_NAMES}
                        component={() => <UserNameSelection/>}
                    />
                    <Route
                        exact path={routes.USER}
                        component={() => <UserMenu/>}
                    />
                    <Route
                        exact path={routes.TRAINING_DECK + '/:deck'}
                        component={() => <JoinBots/>}
                    />
                    <Route
                        exact path={routes.BATTLE_DECK}
                        component={() => <JoinDecks/>}
                    />
                    <Route
                        exact path={routes.TRAINING_DECK}
                        component={() => <JoinDecks/>}
                    />
                    <Route
                        exact path={routes.GAME_LODGE}
                        component={() => <JoinWait/>}
                    />
                    <Route
                        exact path={routes.WATCH_LIST}
                        component={() => <JoinGames/>}
                    />
                </div>
            </Router>
        </MuiThemeProvider>
    );
  }
}

export default App;

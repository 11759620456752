import React from 'react';
import * as routes from '../constants/routes';
import selectRoute from "./selectRoute";
import { withRouter } from 'react-router-dom';
import Button from "@material-ui/core/Button";
import {clearAuthData} from "../util/auth";
import UserAvatarConnector from "./UserAvatarConnector";

class UserMenu extends React.Component {
    constructor(props) {
        super(props);

        this.handleUserDecks = this.handleUserDecks.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
        this.handleBattle = this.handleBattle.bind(this);
        this.handleTraining = this.handleTraining.bind(this);
        this.handleWatch = this.handleWatch.bind(this);
        this.handleChangePicture = this.handleChangePicture.bind(this);
        this.handleChangeName = this.handleChangeName.bind(this);
    }

    handleBattle() {
        this.props.history.push(routes.BATTLE_DECK);
    }

    handleWatch() {
        this.props.history.push(routes.WATCH_LIST);
    }

    handleTraining() {
        this.props.history.push(routes.TRAINING_DECK);
    }

    handleUserDecks() {
        this.props.history.push(routes.USER_DECKS);
    }

    handleChangePicture() {
        this.props.history.push(routes.USER_PICTURES);
    }

    handleChangeName() {
        this.props.history.push(routes.USER_NAMES);
    }

    handleLogout() {
        clearAuthData();
        this.props.history.push(routes.LOGIN);
    }

    render() {
        return (
            <div className="display-inner-container">
                <div className="menu-screen">
                    <UserAvatarConnector/>
                    <Button
                        variant="contained"
                        color="primary"
                        className="menu-button"
                        onClick={this.handleBattle}
                    >Battle</Button>
                    <Button
                        variant="contained"
                        color="primary"
                        className="menu-button"
                        onClick={this.handleWatch}
                    >Watch</Button>
                    <Button
                        variant="contained"
                        color="primary"
                        className="menu-button"
                        onClick={this.handleTraining}
                    >Training</Button>
                    <Button
                        variant="contained"
                        color="primary"
                        className="menu-button"
                        onClick={this.handleUserDecks}
                    >Deck Editor</Button>
                    <Button
                        variant="outlined"
                        color="primary"
                        className="menu-button"
                        onClick={this.handleChangePicture}
                    >Change Picture</Button>
                    <Button
                        variant="outlined"
                        color="primary"
                        className="menu-button"
                        onClick={this.handleChangeName}
                    >Change Name</Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        className="menu-button"
                        onClick={this.handleLogout}
                    >Logout</Button>
                </div>
            </div>
        )
    }
}

export default selectRoute(withRouter(UserMenu));

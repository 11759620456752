import axios from 'axios'
import { getUserToken } from './auth';
import { serverApi } from '../config';

const api = axios.create({
    baseURL: serverApi,
    headers: {
        'Content-Type': 'application/json'
    }
});

// debug
api.interceptors.request.use(request => {
    console.log('Starting Request', request);
    const token = getUserToken();
    if (token !== null && token !== undefined) {
        request.headers['X-Auth-Token'] =  token;
    }
    if (process.env.NODE_ENV === 'development') {
        request.params = {XDEBUG_SESSION_START: 'PHPSTORM'};
    }

    return request
});

api.interceptors.response.use(response => {
    console.log('Response:', response);

    return response
});

export default api;

import React from 'react';
import {getUserName, getUserPicture} from "../util/auth";
import UserAvatar from "./UserAvatar";

export class UserAvatarConnector extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            name: null,
            picture: null,
        };
    }

    componentDidMount() {
        this.setState({
            name: getUserName(),
            picture: getUserPicture(),
        });
    }

    render() {
        return (
            <UserAvatar name={this.state.name} picture={this.state.picture}/>
        );
    }
}

export default UserAvatarConnector;

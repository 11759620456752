import React from 'react';
import {connect} from "react-redux";
import CardView from './CardView';
import PropTypes from "prop-types";
import classnames from "../../util/classnames";
import {getUserHash} from "../../util/auth";
import {Tooltip} from "@material-ui/core";

class PlayerField extends React.Component {
    constructor(props) {
        super(props);

        this.buildPlayerCardCircle = this.buildPlayerCardCircle.bind(this);
        this.handleLocationMouseOver = this.handleLocationMouseOver.bind(this);
        this.handleLocationClick = this.handleLocationClick.bind(this);
        this.handleSoulView = this.handleSoulView.bind(this);
    }

    render() {
        // let objects = [];
        // for (let key in this.props.cards) {
        //     objects.push(<CardView key={key} {...this.props.cards[key]}/>);
        // }
        let columns = [];
        if (this.props.side === 'player') {
            columns.push(
                <div className="field-column" key="col12">
                    {this.buildPlayerCardCircle(this.props.hash, 'rearguard1')}
                    {this.buildPlayerCardCircle(this.props.hash, 'rearguard2')}
                </div>
            );
            columns.push(
                <div className="field-column" key="col03">
                    {this.buildPlayerCardCircle(this.props.hash, 'vanguard')}
                    {this.buildPlayerCardCircle(this.props.hash, 'rearguard3')}
                </div>
            );
            columns.push(
                <div className="field-column" key="col45">
                    {this.buildPlayerCardCircle(this.props.hash, 'rearguard5')}
                    {this.buildPlayerCardCircle(this.props.hash, 'rearguard4')}
                </div>
            );
        } else {
            columns.push(
                <div className="field-column" key="col45">
                    {this.buildPlayerCardCircle(this.props.hash, 'rearguard4')}
                    {this.buildPlayerCardCircle(this.props.hash, 'rearguard5')}
                </div>
            );
            columns.push(
                <div className="field-column" key="col03">
                    {this.buildPlayerCardCircle(this.props.hash, 'rearguard3')}
                    {this.buildPlayerCardCircle(this.props.hash, 'vanguard')}
                </div>
            );
            columns.push(
                <div className="field-column" key="col12">
                    {this.buildPlayerCardCircle(this.props.hash, 'rearguard2')}
                    {this.buildPlayerCardCircle(this.props.hash, 'rearguard1')}
                </div>
            );
        }
        let rearguardNumber = 6;
        while (this.props.field.hasOwnProperty('rearguard' + rearguardNumber)) {
            if (
                (this.props.side === 'player' && rearguardNumber % 2 === 1)
                || (this.props.side !== 'player' && rearguardNumber % 2 === 0)
            ) {
                columns.push(
                    <div className="field-column">
                        {this.buildPlayerCardCircle(this.props.hash, 'rearguard' + rearguardNumber)}
                    </div>
                );
            } else {
                columns.unshift(
                    <div className="field-column">
                        {this.buildPlayerCardCircle(this.props.hash, 'rearguard' + rearguardNumber)}
                    </div>
                );
            }

            rearguardNumber++;
        }

        return (
            <div className={"field-zone " + this.props.side}>
                {columns}
            </div>
        )
    }

    handleLocationMouseOver(location) {
        if (this.props.field[location]) {
            this.props.dispatch({
                'type': 'card.preview',
                'data': {
                    'image': this.props.field[location].image,
                    'name': this.props.field[location].name,
                    'description': this.props.field[location].description,
                },
            });
        }
    }

    handleLocationClick(location) {
        this.props.dispatch({
            type: 'game.click',
            player: this.props.hash,
            data: location,
        });
    }

    handleSoulView() {
        if (Object.keys(this.props.dialog.cards).length === 0) {
            let newActions = this.props.dialog.actions;
            let newTitle = this.props.dialog.title;
            if (Object.keys(this.props.dialog.actions).length > 0) {
                newActions = {close: 'Close soul cards', ...this.props.dialog.actions};
            } else {
                newActions = {close: 'Close soul cards'};
                newTitle = 'Soul cards';
            }

            this.props.dispatch({
                type: 'game.updates',
                data: [
                    {
                        type: 'action.dialog',
                        player: getUserHash(),
                        data: {
                            load_zone: 'soul',
                            load_player_hash: this.props.hash,
                            title: newTitle,
                            actions: newActions,
                        }
                    }
                ]
            });
        }
    }

    buildPlayerCardCircle(playerHash, location) {
        let params = {...this.props.field[location]};
        let soulViewButton = null;
        if (
            this.props.field[location] !== null
            && location === 'vanguard'
        ) {
            params.soul = this.props.soul;
            if (params.soul > 0) {
                soulViewButton = <Tooltip title="View soul zone cards" enterDelay={0}>
                    <div key="view" className="view" onClick={this.handleSoulView}><i className="fas fa-ghost"/></div>
                </Tooltip>;
            }
        }
        params.selected = this.props.selection.indexOf(this.props.hash + '.' + location) !== -1;
        params.opponent = (this.props.side === 'opponent');
        return (
            <div key={location} className={classnames(
                    'card-circle',
                    {
                        'vanguard': location === 'vanguard',
                        'rearguard': location !== 'vanguard',
                        'rest': params.state === 'rest',
                        'force1': params.force1,
                        'accel1': params.accel1,
                        'force2': params.force2,
                        'protect2': params.protect2,
                        'accel2': params.accel2,
                    }
                )}>
                <CardView
                    key={location}
                    onClick={() => this.handleLocationClick(location)}
                    onMouseOver={() => this.handleLocationMouseOver(location)}
                    {...params}
                />
                {soulViewButton}
            </div>
        );
    }
}

PlayerField.propTypes = {
    hash: PropTypes.string.isRequired,
    side: PropTypes.string.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        field: state['player_' + ownProps.hash + '_field'] ? state['player_' + ownProps.hash + '_field'] : [],
        soul: state['player_' + ownProps.hash + '_soul_count'] ? state['player_' + ownProps.hash + '_soul_count'] : 0,
        dialog: state.dialog ? state.dialog : [],
        selection: state.selection ? state.selection : [],
    };
}

export default connect(mapStateToProps)(PlayerField);

import api from '../util/api';
import {getGameHash, getPlayerHash, getUserHash} from "../util/auth";

export function getHandCards(playerHash) {
    return api.get('/api/v1/games/' + getGameHash() + '/players/' + playerHash + '/hand');
}

export function getGameStatus() {
    return api.get('/api/v1/games/' + getGameHash());
}

export function performAction(data) {
    return api.post('/api/v1/games/' + getGameHash(), data);
}

export function performSurrender() {
    return api.post('/api/v1/games/' + getGameHash() + '/players/' + getPlayerHash() + '/surrender');
}

export function getVanguardList() {
    return api.get('/api/v1/games/' + getGameHash() + '/players/' + getPlayerHash() + '/vanguards');
}

export function getSoulCards(playerHash) {
    return api.get('/api/v1/games/' + getGameHash() + '/players/' + playerHash + '/soul');
}

export function getBindCards(playerHash) {
    return api.get('/api/v1/games/' + getGameHash() + '/players/' + playerHash + '/bind');
}

export function getDropCards(playerHash) {
    return api.get('/api/v1/games/' + getGameHash() + '/players/' + playerHash + '/drop');
}

export function getActionUpdate() {
    return api.get('/api/v1/games/' + getGameHash() + '/players/' + getUserHash() + '/action');
}

import React from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHeader from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import CardPreview from './CardPreview';
import Button from '@material-ui/core/Button';
import * as routes from "../constants/routes";
import CircularProgress from '@material-ui/core/CircularProgress';
import {getClanCardList, getClanList} from "../clients/builder";
import {updateDeck, getDeck} from '../clients/decks';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import TableContainer from '@material-ui/core/TableContainer';

class UserBuilder extends React.Component {
    constructor(props) {
        super(props);
        let deckHash = null;
        if (props.match.params.deck !== undefined) {
            deckHash = props.match.params.deck;
        }
        this.handleNumberChange = this.handleNumberChange.bind(this);
        this.onRowHover = this.onRowHover.bind(this);
        this.onRowHoverExit = this.onRowHoverExit.bind(this);
        this.onNameChange = this.onNameChange.bind(this);
        this.onClanChange = this.onClanChange.bind(this);
        this.doDeckSave = this.doDeckSave.bind(this);
        this.handleBack = this.handleBack.bind(this);
        this.state = {
            loading: true,
            deck: deckHash,
            name: '',
            clans: [],
            clan: '',
            cards: [],
            validName: false,
            sentinelCount: 0,
            triggerCount: 0,
            healTriggerCount: 0,
            cardCount: 0,
        };
    }

    componentDidMount() {
        if (this.state.deck) {
            getDeck(this.state.deck).then((response) => {
                let deckData = response.data;
                getClanCardList(deckData.clan).then((response) => {
                    let cards = response.data;
                    let cardCount = cards.length;

                    for (let c=0;c<cardCount;c++) {
                        if (typeof deckData.cards[cards[c].id] != undefined) {
                            cards[c].count = deckData.cards[cards[c].id];
                        }
                    }
                    this.setState({
                        loading: false,
                        name: deckData.name,
                        clan: deckData.clan,
                        cards: cards,
                    }, () => {
                        this.validateDeck();
                    });
                });
            });
        } else {
            getClanList().then( (response) => {
                if (response.data.length > 0) {
                    let clans = response.data;
                    let clan = response.data[0]
                    getClanCardList(clan).then((response) => {
                        this.setState({
                            clans: clans,
                            clan: clan,
                            cards: response.data,
                            loading: false,
                        }, () => {
                            this.validateDeck();
                        });
                    });
                }
            }).catch(() => {
                console.log('error');
                // clearData();
                // this.props.history.push(routes.LOGIN);
            });
        }
        // let loadId = this.state.clan;
        // if (this.state.deck !== null) {
        //     loadId = this.state.deck;
        // }
        // getBuilderCards(loadId).then(function (response) {
        //     let newData = {};
        //     if (response.data.name) {
        //         newData.name = response.data.name;
        //     }
        //     if (response.data.clan) {
        //         newData.clan = response.data.clan;
        //     }
        //     if (response.data.cards) {
        //         newData.cards = response.data.cards;
        //     }
        //     newData.loading = false;
        //     component.setState(newData, () => {
        //         component.validateDeck();
        //     });
        // });
    }

    onClanChange(event) {
        this.setState(
            {
                'clan': event.target.value,
                'loading': true
            },
            () => {
                getClanCardList(this.state.clan).then((response) => {
                    this.setState({
                        cards: response.data,
                        loading: false
                    }, () => {
                        this.validateDeck();
                    });
                })
            }
        );
    }

    onNameChange(event) {
        this.setState({
            name: event.target.value
        }, () => {
            this.validateDeck();
        });
    }

    validateDeck() {
        let cards = this.state.cards;
        let cardCount = cards.length;
        let total = 0;
        let sentinelCount = 0;
        let triggerCount = 0;
        let healCount = 0;
        let nameRegexp = RegExp('^[a-zA-Z0-9 ]{3,20}$');

        for (let c=0;c<cardCount;c++) {
            if (cards[c].count === undefined) {
                continue;
            }
            if (cards[c].shield === 0) {
                sentinelCount += parseInt(cards[c].count);
            }
            if (cards[c].trigger !== null) {
                triggerCount += parseInt(cards[c].count);
                if (cards[c].trigger === 'heal') {
                    healCount += parseInt(cards[c].count);
                }
            }
            total += parseInt(cards[c].count);
        }

        this.setState({
            total: total,
            validName: nameRegexp.test(this.state.name.trim()),
            sentinelCount: sentinelCount,
            triggerCount: triggerCount,
            healTriggerCount: healCount,
            cardCount: total,
        });
    }

    handleNumberChange(event) {
        let cards = this.state.cards;
        let cardCount = cards.length;

        for (let c=0;c<cardCount;c++) {
            if (cards[c].id === event.target.name) {
                cards[c].count = parseInt(event.target.value, 10) || 0;
                break;
            }
        }

        this.setState({
            cards: cards,
        }, () => {
            this.validateDeck();
        });
    }

    onRowHover(rowNumber) {
        if (this.state.loading) {
            return;
        }
        if (this.state.cards[rowNumber].image !== null) {
            this.props.dispatch({
                'type': 'card.preview',
                'data': {
                    'image': this.state.cards[rowNumber].image,
                    'name': this.state.cards[rowNumber].name,
                    'description': this.state.cards[rowNumber].description,
                },
            });
        }
    }

    onRowHoverExit(rowNumber) {
        if (this.state.loading) {
            return;
        }
        // if (this.state.cards[rowNumber].image !== null) {
        //     this.props.dispatch({
        //         'type': 'card.preview',
        //         'data': null,
        //     });
        // }
    }

    doDeckSave() {
        let data = {
            name: this.state.name,
            clan: this.state.clan,
            cards: {},
        };

        let cardCount = this.state.cards.length;
        for (let c=0;c<cardCount;c++) {
            if (parseInt(this.state.cards[c].count) > 0) {
                data.cards[this.state.cards[c].id] = parseInt(this.state.cards[c].count);
                // data.cards.push(this.state.cards[c].id + '#' + this.state.cards[c].count);
            }
        }

        updateDeck(this.state.deck, data).then(() => {
            this.props.history.push(routes.USER_DECKS);
        });
    }

    handleBack() {
        this.props.history.push(routes.USER_DECKS);
    }

    render() {
        let cards = [];
        let validation = [];
        let cardCount = this.state.cards.length;
        let cardNumberError = false;
        if (this.state.loading) {
            cards.push(
                <TableRow key="loading">
                    <TableCell key="loading"><CircularProgress key="progress"/></TableCell>
                </TableRow>
            );
        } else {
            for (let c=0;c<cardCount;c++) {
                let error = false;
                if (
                    parseInt(this.state.cards[c].count) < 0
                    || parseInt(this.state.cards[c].count) > 4
                ) {
                    error = true;
                    cardNumberError = true;
                }
                cards.push(
                    // onRowHover={this.onRowHover} onRowHoverExit={this.onRowHoverExit}
                    <TableRow hover={true} key={this.state.cards[c].id} onMouseEnter={() => this.onRowHover(c)} onMouseLeave={() => this.onRowHoverExit(c)}>
                        <TableCell padding="none" key="name">{this.state.cards[c].name}</TableCell>
                        <TableCell align="center" padding="none" key="grade">{this.state.cards[c].grade}</TableCell>
                        <TableCell align="center" padding="none" key="power">{this.state.cards[c].power}</TableCell>
                        <TableCell align="center" padding="none" key="shield">{this.state.cards[c].shield}</TableCell>
                        <TableCell align="center" padding="none" key="trigger">{this.state.cards[c].trigger}</TableCell>
                        <TableCell padding="none" key="number">
                            <TextField
                                key="number"
                                type="number"
                                inputProps={{ min: 0, max: 4 }}
                                id={this.state.cards[c].id}
                                name={this.state.cards[c].id}
                                defaultValue={this.state.cards[c].count}
                                onChange={this.handleNumberChange}
                                error={error}
                            />
                        </TableCell>
                    </TableRow>
                );
            }
        }

        let invalidDeck = false;
        let nameValidationClass = "validation-ok";
        if (!this.state.validName) {
            nameValidationClass = "validation-error";
            invalidDeck = true;
        }
        validation.push(<li key="name" className={nameValidationClass}>Valid deck name: 3-20 characters ({this.state.name.trim().length})</li>);

        let deckValidationClass = "validation-ok";
        if (this.state.cardCount !== 50) {
            deckValidationClass = "validation-error";
            invalidDeck = true;
        }
        validation.push(<li key="count" className={deckValidationClass}>Deck size must be 50 ({this.state.cardCount})</li>);

        let sentinelValidationClass = "validation-ok";
        if (this.state.sentinelCount > 4) {
            sentinelValidationClass = "validation-error";
            invalidDeck = true;
        }
        validation.push(<li key="sentinel" className={sentinelValidationClass}>Sentinel count is limited to 4 ({this.state.sentinelCount})</li>);

        let triggerValidationClass = "validation-ok";
        if (this.state.triggerCount !== 16) {
            triggerValidationClass = "validation-error";
            invalidDeck = true;
        }
        validation.push(<li key="trigger" className={triggerValidationClass}>Trigger count must be 16 ({this.state.triggerCount})</li>);

        let healValidationClass = "validation-ok";
        if (this.state.healTriggerCount > 4) {
            healValidationClass = "validation-error";
            invalidDeck = true;
        }
        validation.push(<li key="heal" className={healValidationClass}>Heal trigger count is limited to 4 ({this.state.healTriggerCount})</li>);

        let cardNumberValidationClass = "validation-ok";
        if (cardNumberError) {
            cardNumberValidationClass = "validation-error";
            invalidDeck = true;
        }
        validation.push(<li key="number" className={cardNumberValidationClass}>Same card copy count is limited to 4</li>);

        let clans = [];
        if (this.state.deck === null) {
            for (let key in this.state.clans) {
                clans.push(<MenuItem value={this.state.clans[key]} key={key}>{this.state.clans[key]}</MenuItem>);
            }
        } else {
            clans.push(<MenuItem value={this.state.clan} key={0}>{this.state.clan}</MenuItem>);
        }
        return (
            <>
                <div className="sidebar">
                    <CardPreview/>
                    <FormControl className="builder-clan-select" disabled={this.state.deck !== null}>
                        <InputLabel htmlFor="builder-clan-select">Clan</InputLabel>
                        <Select
                            variant="filled"
                            onChange={this.onClanChange}
                            value={this.state.clan}
                        >
                            {clans}
                        </Select>
                        </FormControl>
                    <TextField
                        onChange={this.onNameChange}
                        fullWidth={true}
                        value={this.state.name}
                        variant="standard"
                        label="Deck name"
                        inputProps={{ maxLength: 20}}
                        error={!this.state.validName}
                    />
                    <ul>
                        {validation}
                    </ul>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={invalidDeck}
                        onClick={this.doDeckSave}
                    >Save</Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={this.handleBack}
                    >Back</Button>
                </div>
                <div className="field">
                    <TableContainer className="builder-card-table">
                        <Table stickyHeader>
                            <TableHeader>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Grade</TableCell>
                                    <TableCell>Power</TableCell>
                                    <TableCell>Shield</TableCell>
                                    <TableCell>Trigger</TableCell>
                                    <TableCell style={{width: '10px'}}>#</TableCell>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {cards}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </>
        )
    }
}

export default withRouter(connect(null)(UserBuilder));

import React from 'react';
import {connect} from "react-redux";
import { withRouter } from 'react-router-dom';
import Pusher from 'pusher-js';
import * as routes from '../constants/routes';
import {clearGameData, getGameHash, getOpponentHash, getPlayerHash, getUserHash} from "../util/auth";
import {getGameStatus, performSurrender} from '../clients/game';
import selectRoute from "./selectRoute";
import CardPreview from './CardPreview';
import {pusherAppId} from '../config';
import ChatLog from './game/ChatLog';
import GamePlayerAvatarConnector from "./GamePlayerAvatarConnector";
import PlayerHand from "./game/PlayerHand";
import PlayerField from "./game/PlayerField";
import GuardianZone from "./game/GuardianZone";
import TriggerZone from "./game/TriggerZone";
import DamageZone from "./game/DamageZone";
import DeckCircle from "./game/DeckCircle";
import DropCircle from "./game/DropCircle";
import PlayerDialog from "./game/PlayerDialog";
import SurrenderDialog from "./game/SurrenderDialog";
import {Tooltip} from "@material-ui/core";

Pusher.logToConsole = true;

class GamePage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pusher: null,
            showExit: false,
        };

        this.handleShowExitDialog = this.handleShowExitDialog.bind(this);
        this.handleSurrender = this.handleSurrender.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }

    componentDidMount() {
        this.setState({pusher: this.initiatePusher()});
    }

    initiatePusher() {
        console.log('initiating pusher');
        let pusher = new Pusher(pusherAppId, {
            cluster: 'eu',
            encrypted: true
        });

        let channel = pusher.subscribe('game-' + getGameHash());
        console.log(channel);
        let component = this;
        channel.bind('pusher:subscription_succeeded', function(data) {
            console.log('subscribed', data);
            component.notifyServer();
        });
        channel.bind('game.update', (data) =>  {
            console.log('update data', data);
            this.props.dispatch(data);
        });
    }

    notifyServer() {
        console.log('notify server');
        getGameStatus().then((response) => {
            console.log(response.data);
            this.props.dispatch(response.data);
        }).catch((error) => {
            clearGameData();
            console.log('error notifying server', error);
            this.props.history.push(routes.LOGIN);
        });
    }

    handleShowExitDialog() {
        if (this.props.finished || getPlayerHash() !== getUserHash()) {
            this.props.dispatch({
                type: 'game.clear'
            });
            clearGameData();
            this.props.history.push(routes.USER);
        } else {
            this.setState({
                showExit: true
            });
        }
    }

    handleSurrender() {
        performSurrender().then(() => {
            this.setState({
                showExit: false,
            })
        });
    }

    handleCancel() {
        this.setState({
            showExit: false,
        });
    }

    render() {

        let exitDialog = null;
        if (this.state.showExit) {
             exitDialog = <SurrenderDialog onCancel={this.handleCancel} onSurrender={this.handleSurrender}/>;
        }
        return (
            <>
                <PlayerDialog/>
                {exitDialog}
                <div className="sidebar">
                    <CardPreview/>
                    <ChatLog/>
                </div>
                <div className="field">
                    <div className="single-line opponent">
                        <PlayerHand hash={getOpponentHash()}/>
                        <GamePlayerAvatarConnector hash={getOpponentHash()}/>
                    </div>
                    <div className="double-line opponent">
                        <div className="field-column">
                            <DropCircle hash={getOpponentHash()}/>
                            <DeckCircle hash={getOpponentHash()}/>
                        </div>
                        <PlayerField hash={getOpponentHash()} side="opponent"/>
                        <DamageZone hash={getOpponentHash()}/>
                    </div>
                    <div className="thin-line">
                        <TriggerZone hash={getOpponentHash()}/>
                        <GuardianZone/>
                        <TriggerZone hash={getPlayerHash()}/>
                    </div>
                    <div className="double-line">
                        <DamageZone hash={getPlayerHash()}/>
                        <PlayerField hash={getPlayerHash()} side="player"/>
                        <div className="field-column">
                            <DeckCircle hash={getPlayerHash()}/>
                            <DropCircle hash={getPlayerHash()}/>
                        </div>
                    </div>
                    <div className="single-line">
                        <GamePlayerAvatarConnector hash={getPlayerHash()}>
                            <Tooltip title="Leave this game" enterDelay={0}>
                                <div className="exit" key="exit" onClick={this.handleShowExitDialog}><i className="fas fa-sign-out-alt"></i></div>
                            </Tooltip>
                        </GamePlayerAvatarConnector>
                        <PlayerHand hash={getPlayerHash()}/>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        finished: state.finished,
    };
}

export default selectRoute(withRouter(connect(mapStateToProps)(GamePage)));

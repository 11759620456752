import React from 'react';
import PropTypes from 'prop-types';

class UserAvatar extends React.Component {
    render() {
        let picture = null;
        let name = null;
        if (this.props.picture) {
            picture = <img className="avatar" src={this.props.picture} alt={this.props.name}/>;
        }
        if (this.props.name) {
            name = <div className="name">{this.props.name.substring(0, 20)}</div>;
        }

        return (
            <div className="profile">
                {picture}
                {name}
                {this.props.children}
            </div>
        );
    }
}

UserAvatar.propTypes = {
    picture: PropTypes.string,
    name: PropTypes.string,
};

export default UserAvatar;

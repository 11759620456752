import React from 'react';
import {withRouter} from "react-router-dom";
import * as routes from "../constants/routes";
import selectRoute from "./selectRoute";
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import {cancelGame, getActiveGames, updateStatus} from '../clients/join';
import {clearGameData, getOpponentHash, setAuthData} from "../util/auth";
import {Typography} from "@material-ui/core";
import UserAvatarConnector from "./UserAvatarConnector";
import UserAvatar from "./UserAvatar";

class JoinGames extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            games: null,
        };

        this.handleBack = this.handleBack.bind(this);
        this.handleJoin = this.handleJoin.bind(this);
    }

    componentDidMount() {
        getActiveGames().then((response) => {
            console.log(response.data);
            this.setState({
                games: response.data.games,
            });
        });
    }

    handleBack() {
        this.props.history.push(routes.USER);
    }

    handleJoin(key) {
        setAuthData({
            'game_hash': this.state.games[key].hash,
            'player_hash': this.state.games[key].player0_hash,
            'opponent_hash': this.state.games[key].player1_hash,
        });
        this.props.history.push(routes.GAME);
    }

    render() {
        let listItems = [];
        listItems.push(
            <Typography key="title" style={{color: 'white'}} variant="subtitle1">Active games you can watch</Typography>
        );
        if (this.state.games === null) {
            listItems.push(
                <CircularProgress key="waiting" style={{marginLeft: '50%', left: '-20px', position: 'relative'}}/>
            );
        }
        if (this.state.games !== null) {
            if (this.state.games.length === 0) {
                listItems.push(
                    <Typography key="empty" style={{color: 'white'}} variant="subtitle1">No active games</Typography>
                );
            } else {
                for (let key in this.state.games) {
                    listItems.push(<Button
                        variant="contained"
                        className="menu-button"
                        color="primary"
                        key={key}
                        onClick={() => this.handleJoin(key)}
                    >{this.state.games[key].player0_name} vs {this.state.games[key].player1_name}</Button>
                    );

                    // let opponentAvatar = this.state.games[key].player1_avatar ?? '/images/avatar/male1.png';
                    // let opponentName = this.state.games[key].player1_name ?? 'Waiting...';
                    // listItems.push(<>
                    //     <UserAvatar name={this.state.games[key].player0_name} picture={this.state.games[key].player0_avatar}/>
                    //     <UserAvatar name={opponentName} picture={opponentAvatar}/>
                    //     </>)
                }
            }
        }



        return (
            <div className="menu-screen">
                <UserAvatarConnector/>
                {listItems}
                <Button
                    variant="contained"
                    className="menu-button"
                    color="secondary"
                    key="back"
                    onClick={this.handleBack}
                >Back</Button>
            </div>
        );
    }
}

export default selectRoute(withRouter(JoinGames));

import React from 'react';
import {connect} from "react-redux";
import UserAvatar from "./UserAvatar";
import PropTypes from "prop-types";

class GamePlayerAvatarConnector extends React.Component {
    render() {
        return (
            <UserAvatar name={this.props.name} picture={this.props.picture}>
                {this.props.children}
            </UserAvatar>
        );
    }
}

GamePlayerAvatarConnector.propTypes = {
    hash: PropTypes.string.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        name: state['player_' + ownProps.hash + '_name'],
        picture: state['player_' + ownProps.hash + '_avatar'],
    };
}

export default connect(mapStateToProps)(GamePlayerAvatarConnector);

import React from 'react';
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { withRouter } from 'react-router-dom';
import * as routes from '../constants/routes';
import {clearAuthData, setAuthData} from '../util/auth';
import selectRoute from './selectRoute';
import Button from '@material-ui/core/Button';
import {
    sendFacebookToken,
    sendGoogleToken,
    sendDummyToken,
    sendDummy2Token
} from "../clients/login";

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.handleGoogle = this.handleGoogle.bind(this);
        this.handleFacebook = this.handleFacebook.bind(this);
        this.handleDummy = this.handleDummy.bind(this);
        this.state = {error: false};
    }

    handleFacebook = (data) => {
        clearAuthData();
        sendFacebookToken(data.accessToken).then((response) => {
            setAuthData(response.data);
            this.props.history.push(routes.USER);
        }).catch(() => {
            this.setState({error: true});
        });
    };

    handleGoogle = (response) => {
        clearAuthData();
        sendGoogleToken(response.tokenId).then((response) => {
            setAuthData(response.data);
            this.props.history.push(routes.USER);
        }).catch(() => {
            this.setState({error: true});
        });
    };

    handleFailure = () => {
        this.setState({error: true});
    };

    handleDummy = () => {
        clearAuthData();
        sendDummyToken().then( (response) => {
            setAuthData(response.data);
            this.props.history.push(routes.USER);
        }).catch(() => {
            this.setState({error: true});
        });
    };

    handleDummy2 = () => {
        clearAuthData();
        sendDummy2Token().then( (response) => {
            setAuthData(response.data);
            this.props.history.push(routes.USER);
        }).catch(() => {
            this.setState({error: true});
        });
    };

    render() {
        return (
            <div className="display-inner-container">
                <div className="menu-screen menu-screen-login">
                    { this.state.error && <div>Error logging</div> }
                    <GoogleLogin key="google"
                        clientId="27655706627-aa2p2ultr1q3t6hn43orkgt54o1h43os.apps.googleusercontent.com"
                        render={renderProps => (
                            <Button
                                onClick={renderProps.onClick} disabled={renderProps.disabled}
                                variant="contained"
                                color="primary"
                                className="menu-button"
                            >Login with Google</Button>
                        )}
                        buttonText="Login with Google"
                        theme="dark"
                        style={{fontFamily: "Roboto", fontWeight: "bold"}}
                        onSuccess={this.handleGoogle}
                        onFailure={this.handleFailure}
                    />
                    <FacebookLogin key="facebook"
                        appId="263182044223995"
                        autoLoad={false}
                        render={renderProps => (
                            <Button
                                onClick={renderProps.onClick} disabled={renderProps.disabled}
                                variant="contained"
                                color="primary"
                                className="menu-button"
                            >Login with Facebook</Button>
                        )}
                        onFailure={this.handleFailure}
                        callback={this.handleFacebook} />
                    <Button
                        color="primary"
                        variant="contained"
                        className="menu-button"
                        onClick={this.handleDummy}
                    >Login Demo user</Button>
                    <Button
                        color="primary"
                        variant="contained"
                        className="menu-button"
                        onClick={this.handleDummy2}
                    >Login Demo2 user</Button>
                </div>
            </div>
        )
    }
}

export default selectRoute(withRouter(Login));

import React from 'react';
import * as routes from '../constants/routes';
import {getNames, updateName} from "../clients/profile";
import selectRoute from "./selectRoute";
import { withRouter } from 'react-router-dom';
import Button from "@material-ui/core/Button";
import {getUserName, setAuthData} from "../util/auth";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import UserAvatarConnector from "./UserAvatarConnector";
import Typography from "@material-ui/core/Typography";

class UserNameSelection extends React.Component {
    constructor(props) {
        super(props);

        this.handleBack = this.handleBack.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.state = {
            name: null,
            names: [],
        };
    }

    componentDidMount() {
        getNames().then((response) => {
            this.setState({
                name: getUserName(),
                names: response.data.names,
            });
        });
    }

    handleChange(event) {
        this.setState({name: event.target.value});
    }

    handleUpdate() {
        updateName({
            name: this.state.name,
        }).then((response) => {
            setAuthData(response.data);
            this.props.history.push(routes.USER);
        });
    }

    handleBack() {
        this.props.history.push(routes.USER);
    }

    render() {
        let names = [];
        for (let key in this.state.names) {
            names.push(
                <FormControlLabel key={key} value={this.state.names[key]} control={<Radio />} label={this.state.names[key].substring(0, 20)} />
            );
        }

        return (
            <div className="display-inner-container">
                <div className="menu-screen">
                    <UserAvatarConnector/>
                    <Typography color="textPrimary">Choose new name</Typography>
                    <FormControl component="fieldset" className="user-name-container">
                        <RadioGroup name="user-name" value={this.state.name} onChange={this.handleChange}>
                            {names}
                        </RadioGroup>
                    </FormControl>
                    <Button
                        variant="contained"
                        className="menu-button"
                        color="primary"
                        key="change"
                        onClick={this.handleUpdate}
                    >Change</Button>
                    <Button
                        variant="contained"
                        className="menu-button"
                        color="secondary"
                        key="back"
                        onClick={this.handleBack}
                    >Back</Button>
                </div>
            </div>
        )
    }
}

export default selectRoute(withRouter(UserNameSelection));

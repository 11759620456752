import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";

class SurrenderDialog extends React.PureComponent {
    constructor(props) {
        super(props);

        this.handleSurrender = this.handleSurrender.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }

    handleSurrender() {
        if (this.props.onSurrender) {
            this.props.onSurrender();
        }
    }

    handleCancel() {
        if (this.props.onCancel) {
            this.props.onCancel();
        }
    }

    render() {
        return (
            <Dialog maxWidth="xs" open={true}>
                <DialogContent>
                    <Typography variant="body2" key="title">Do you want to surrender?</Typography>
                </DialogContent>
                <div className="dialog-actions">
                    <Button
                        variant="contained"
                        key="surrender"
                        onClick={this.handleSurrender}
                        color="primary"
                        className="menu-button"
                    >Surrender</Button>
                    <Button
                        variant="contained"
                        key="cancel"
                        onClick={this.handleCancel}
                        color="secondary"
                        className="menu-button"
                    >Cancel</Button>
                </div>
            </Dialog>
        );
    }
}

SurrenderDialog.propTypes = {
    onSurrender: PropTypes.func,
    onCancel: PropTypes.func,
};

export default SurrenderDialog;

import React from 'react';
import {connect} from "react-redux";
import CardView from './CardView';
import PropTypes from "prop-types";
import {getHandCards} from "../../clients/game";

class PlayerHand extends React.Component {
    constructor(props) {
        super(props);

        this.fetchHandCards = this.fetchHandCards.bind(this);
        this.handleCardMouseOver = this.handleCardMouseOver.bind(this);
        this.handleCardClick = this.handleCardClick.bind(this);
    }

    componentDidMount() {
        this.fetchHandCards();
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.update
            && prevProps.update !== this.props.update
        ) {
            this.fetchHandCards();
        }
    }

    handleCardMouseOver(id) {
        if (this.props.cards[id]) {
            this.props.dispatch({
                'type': 'card.preview',
                'data': {
                    'image': this.props.cards[id].image,
                    'name': this.props.cards[id].name,
                    'description': this.props.cards[id].description,
                },
            });
        }
    }

    handleCardClick(id) {
        this.props.dispatch({
            type: 'game.click',
            player: this.props.hash,
            data: id,
        });
    }

    fetchHandCards() {
        getHandCards(this.props.hash).then((response) => {
            this.props.dispatch({
                'type': 'game.updates',
                'data': [
                    {
                        'type': 'hand.cards',
                        'player': this.props.hash,
                        'data': response.data
                    },
                    {
                        'type': 'hand.update',
                        'player': this.props.hash,
                        'data': false
                    },
                ]
            });
        });
    }

    render() {
        let objects = [];
        for (let key in this.props.cards) {
            let params = {...this.props.cards[key]};
            params['selected'] = this.props.selection.indexOf(this.props.hash + '.' + key) !== -1;
            objects.push(<CardView
                key={key}
                {...params}
                onMouseOver={() => this.handleCardMouseOver(key)}
                onClick={() => this.handleCardClick(key)}
            />);
        }
        return (
            <div className="hand">
                {objects}
            </div>
        )
    }
}

PlayerHand.propTypes = {
    hash: PropTypes.string.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        cards: state['player_' + ownProps.hash + '_hand_cards'] ? state['player_' + ownProps.hash + '_hand_cards'] : [],
        update: state['player_' + ownProps.hash + '_hand_update'] ? state['player_' + ownProps.hash + '_hand_update'] : false,
        selection: state.selection ? state.selection : [],
    };
}

export default connect(mapStateToProps)(PlayerHand);

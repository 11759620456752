import React from 'react';
import PropTypes from 'prop-types';
import classnames from "../../util/classnames";

export class CardView extends React.Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.handleMouseOver = this.handleMouseOver.bind(this);
        this.handleMouseOut = this.handleMouseOut.bind(this);
    }

    handleClick() {
        if (this.props.onClick !== undefined) {
            this.props.onClick();
        }
    }

    handleMouseOver() {
        if (this.props.onMouseOver) {
            this.props.onMouseOver();
        }
    }

    handleMouseOut() {
        if (this.props.onMouseOut) {
            this.props.onMouseOut();
        }
    }

    render() {
        return (
            <div className={classnames("card", {"selected": this.props.selected})} onClick={this.handleClick} onMouseOver={this.handleMouseOver} onMouseOut={this.handleMouseOut}>
                {this.props.image !== undefined &&
                    <img className="image" src={this.props.image ? this.props.image : '/images/field/deck.png'} alt="test"/>
                }
                {this.props.force1 !== undefined &&
                    <div className="header force1"><span className="count">x{this.props.force1}</span></div>
                }
                {this.props.grade !== undefined &&
                <div className="grade">{this.props.grade}</div>
                }
                {this.props.protect1 !== undefined &&
                    <div className="header protect1"><span className="count">x{this.props.protect1}</span></div>
                }
                {this.props.accel1 !== undefined &&
                    <div className="header accel1"><span className="count">x{this.props.accel1}</span></div>
                }
                {this.props.skill !== undefined &&
                    <div className={"skill " + this.props.skill}/>
                }
                {this.props.gift !== undefined &&
                    <div className={"gift " + this.props.gift}>{this.props.gift}</div>
                }
                {this.props.shield !== undefined &&
                    <div className="shield">{this.props.shield}</div>
                }
                {this.props.soul !== undefined &&
                    <div className="soul"><i className="fas fa-ghost"/><br/>{this.props.soul}</div>
                }
                {this.props.gauge !== undefined &&
                    <div className="gauge">{this.props.gauge}</div>
                }
                {this.props.attacker && !this.props.opponent &&
                    <div className="combat"><i className="fa fa-5x fa-angle-up"></i></div>
                }
                {this.props.attacker && this.props.opponent &&
                    <div className="combat"><i className="fa fa-5x fa-angle-down"></i></div>
                }
                {this.props.booster  && !this.props.opponent &&
                    <div className="combat"><i className="fa fa-5x fa-angle-double-up"></i></div>
                }
                {this.props.booster  && this.props.opponent &&
                    <div className="combat"><i className="fa fa-5x fa-angle-double-down"></i></div>
                }
                {this.props.defender &&
                    <div className="combat"><i className="fa fa-5x fa-times"></i></div>
                }
                {(this.props.power !== undefined || this.props.critical !== undefined) &&
                    <div className="footer">
                        {this.props.power !== undefined &&
                            <span className="power">{this.props.power}</span>
                        }
                        {this.props.critical !== undefined &&
                            <span className="critical">{this.props.critical}</span>
                        }
                    </div>
                }
            </div>
        );
    }
}

CardView.propTypes = {
    grade: PropTypes.number,
    image: PropTypes.string,
    force: PropTypes.number,
    protect: PropTypes.number,
    accel: PropTypes.number,
    skill: PropTypes.string,
    gift: PropTypes.string,
    shield: PropTypes.number,
    soul: PropTypes.number,
    gauge: PropTypes.number,
    power: PropTypes.number,
    critical: PropTypes.number,
    selected: PropTypes.bool,
    attacker: PropTypes.bool,
    booster: PropTypes.bool,
    defender: PropTypes.bool,
    opponent: PropTypes.bool,
    onClick: PropTypes.func,
    onMouseOver: PropTypes.func,
    onMouseOut: PropTypes.func,
};

export default CardView;

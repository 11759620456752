import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import {connect} from "react-redux";
import {getPlayerHash} from "../../util/auth";
import store from "../../util/store";
import CardView from "./CardView";
import {
    getActionUpdate,
    getBindCards,
    getDropCards,
    getSoulCards,
    getVanguardList,
    performAction
} from "../../clients/game";
import Typography from "@material-ui/core/Typography";

class PlayerDialog extends React.Component {
    constructor(props) {
        super(props);

        this.handleCardSelect = this.handleCardSelect.bind(this);
        this.handleCardMouseOver = this.handleCardMouseOver.bind(this);
        this.handleActionClick = this.handleActionClick.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.dialog.load_zone !== prevProps.dialog.load_zone
            && this.props.dialog.load_zone !== null
        ) {
            if (this.props.dialog.load_zone === 'vanguard') {
                getVanguardList().then((response) => {
                    // loading = false;
                    store.dispatch({
                        type: 'action.dialog',
                        player: getPlayerHash(),
                        data: {
                            load_zone: null,
                            cards: response.data
                        },
                    });
                });
            } else if (this.props.dialog.load_zone === 'drop') {
                getDropCards(this.props.dialog.load_player_hash).then((response) => {
                    // loading = false;
                    store.dispatch({
                        type: 'action.dialog',
                        player: getPlayerHash(),
                        data: {
                            load_zone: null,
                            load_player_hash: null,
                            cards: response.data,
                        },
                    });
                });
            } else if (this.props.dialog.load_zone === 'soul') {
                getSoulCards(this.props.dialog.load_player_hash).then((response) => {
                    store.dispatch({
                        type: 'action.dialog',
                        player: getPlayerHash(),
                        data: {
                            load_zone: null,
                            load_player_hash: null,
                            cards: response.data,
                        },
                    });
                });
            } else if (this.props.dialog.load_zone === 'bind') {
                getBindCards(this.props.dialog.load_player_hash).then((response) => {
                    store.dispatch({
                        type: 'action.dialog',
                        player: getPlayerHash(),
                        data: {
                            load_zone: null,
                            load_player_hash: null,
                            cards: response.data,
                        },
                    });
                });
            } else if (this.props.dialog.load_zone === 'gauge') {
                console.log('Gauge view not implemented');
            } else if (this.props.dialog.load_zone === 'action') {
                getActionUpdate().then((response) => {
                    store.dispatch(response.data);
                })
            }
        }
    }

    handleCardMouseOver(id) {
        if (this.props.dialog.cards[id]) {
            this.props.dispatch({
                'type': 'card.preview',
                'data': {
                    'image': this.props.dialog.cards[id].image,
                    'name': this.props.dialog.cards[id].name,
                    'description': this.props.dialog.cards[id].description,
                },
            });
        }
    }

    handleCardSelect(id) {
        this.props.dispatch({
            'type': 'game.click',
            'data': id,
        });
    }

    handleActionClick(action) {
        if (action === 'close') { // view? just close cards
            if (Object.keys(this.props.dialog.actions).length === 1) {
                this.props.dispatch({
                    'type': 'game.updates',
                    'data': [
                        {
                            'type': 'action.clear',
                            'player': getPlayerHash()
                        }
                    ]
                });
            } else {
                let newActions = {...this.props.dialog.actions};
                delete newActions['close'];
                this.props.dispatch({
                    'type': 'game.updates',
                    'data': [
                        {
                            'type': 'action.dialog',
                            'player': getPlayerHash(),
                            'data': {
                                cards: [],
                                actions: newActions
                            }
                        }
                    ]
                });

            }
        } else {
            let data = [];
            let strippedSelection = [...this.props.selection];
            strippedSelection = strippedSelection.map((element) => {
                let index = element.indexOf('.');
                if (index === -1) {
                    return element;
                }
                return element.substr(index + 1);
            });
            if (action === 'send') {
                data = strippedSelection;
            } else if (strippedSelection.length > 0) {
                data = {
                    cards: strippedSelection,
                    action: action,
                };
            } else {
                data = [
                    action
                ]
            }

            performAction(data).then((response) => {
                this.props.dispatch(response.data);
            });
        }
    }

    handle(event) {
        this.props.dispatch(event);
    }

    render() {
        let openDialog = Object.keys(this.props.dialog.cards).length > 0 || Object.keys(this.props.dialog.actions).length > 1;
        let open = (this.props.dialog.type !== null && this.props.dialog.load_zone === null) || openDialog;
        // open dialog if we have a type and already loaded zone cards (also possibly check if we have buttons? just in case we would need those)

        let cards = [];
        let buttons = [];

        for (let key in this.props.dialog.cards) {
            let cardProps = {
                ...this.props.dialog.cards[key],
                selected: (this.props.selection.indexOf(key) !== -1)
            };

            cards.push(<CardView
                key={key}
                onClick={() => this.handleCardSelect(key)}
                onMouseOver={() => this.handleCardMouseOver(key)}
                {...cardProps}
            />);
        }
        for (let key in this.props.dialog.actions) {
            buttons.push(<Button
                    variant="contained"
                    key={key}
                    onClick={() => this.handleActionClick(key)}
                    color="primary"
                    className={openDialog ? "menu-button" : ''}
                >{this.props.dialog.actions[key]}</Button>);
        }

        if (open) {
            let messages = [];
            if (this.props.error) {
                messages.push(<Typography key="error" color="error" variant="body2"><i className="fa fa-exclamation-triangle"/> {this.props.error}</Typography>)
                messages.push(<hr key="divide"/>)
            }
            if (typeof this.props.dialog.title === 'string' ) {
                messages.push(<Typography variant="body2" key="title">{this.props.dialog.title}</Typography>)
            } else {
                for (let key in this.props.dialog.title) {
                    messages.push(<Typography variant="body2" key={key}>{this.props.dialog.title[key]}</Typography>)
                }
            }
            if (cards.length > 0) {
                messages.push(<div key="cards" className="dialog-cards">
                        {cards}
                    </div>);
            }
            if (openDialog) {
                // let dialogTitle = null;
                // if (typeof this.props.dialog.title === 'string' ) {
                //     let dialogTitle = <DialogTitle><Typography variant="body2" key="title">{this.props.dialog.title}</Typography></DialogTitle>;
                // } else {
                //     for (let key in this.props.dialog.title) {
                //         messages.push(<Typography variant="body2" key={key}>{this.props.dialog.title[key]}</Typography>)
                //     }
                // }
                return (
                    <Dialog
                        maxWidth="xs"
                        open={open}
                    >
                        <DialogContent>
                            {messages}
                        </DialogContent>
                        <div className="dialog-actions">
                            {buttons}
                        </div>
                    </Dialog>
                );
            } else {
                return (
                    <Snackbar
                        open={open}
                        message={<>
                            {messages}
                        </>}
                        anchorOrigin={{horizontal: 'center', vertical: 'top'}}
                        action={buttons}
                    >
                    </Snackbar>
                );
            }
        } else {
            return ('');
        }
    }
}

function mapStateToProps(state) {
    return {
        dialog: state.dialog,
        selection: state.selection,
        error: state.error,
    }
}

export default connect(mapStateToProps)(PlayerDialog);

import React from 'react';
import PropTypes from 'prop-types';
import Typography from "@material-ui/core/Typography";

class CardLink extends React.PureComponent {
    constructor(props) {
        super(props);

        this.handleMouseOver = this.handleMouseOver.bind(this);
    }

    render() {
        return (
            <Typography variant="body2" display="inline"
                className="card-link"
                onMouseOver={this.handleMouseOver}
                component="span"
                // onMouseOut={this.handleMouseOut}
            >{this.props.name}</Typography>
        )
    }

    handleMouseOver() {
        if (this.props.onMouseOver) {
            this.props.onMouseOver(
                this.props.name,
                this.props.image,
                this.props.description
            );
        }
    }
}

CardLink.propTypes = {
    name: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    onMouseOver: PropTypes.func.isRequired,
};

export default CardLink;

import React from 'react';
import {withRouter} from "react-router-dom";
import * as routes from "../constants/routes";
import selectRoute from "./selectRoute";
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import {deleteDeck, getDecks} from '../clients/decks';
import UserAvatarConnector from "./UserAvatarConnector";
import IconButton from '@material-ui/core/IconButton';
import Typography from "@material-ui/core/Typography";

class UserDecks extends React.Component {
    constructor(props) {
        super(props);
        this.handleDeckBuilder = this.handleDeckBuilder.bind(this);
        this.handleDeckEdit = this.handleDeckEdit.bind(this);
        this.handleDeckDelete = this.handleDeckDelete.bind(this);
        this.handleBack = this.handleBack.bind(this);
        this.state = {
            decks: null
        };
    }

    componentDidMount() {
        getDecks().then((response) => {
            this.setState({decks: response.data});
        });
    }

    handleDeckEdit(deckHash) {
        this.props.history.push(routes.USER_BUILDER + '/' + deckHash);
    }

    handleDeckDelete(deckHash) {
        deleteDeck(deckHash).then(() => {
            getDecks().then((response) => {
                this.setState({decks: response.data});
            });
        });
    }

    handleDeckBuilder() {
        this.props.history.push(routes.USER_BUILDER);
    }

    handleBack() {
        this.props.history.push(routes.USER);
    }

    render() {
        let listItems = [];
        listItems.push(<Typography key="label" color="textPrimary">Choose deck</Typography>)
        if (this.state.decks === null) {
            listItems.push(
                <CircularProgress key="load" style={{marginLeft: '50%', left: '-20px', position: 'relative'}}/>
            );
        } else {
            for (let key in this.state.decks) {
                let deckHash = this.state.decks[key].id;
                let deckLogo = '/images/clan/' + this.state.decks[key].clan + '.png';
                listItems.push(
                    <div className="deck-row" key={deckHash}>
                        <IconButton
                            key="edit"
                            className="deck-button-action"
                            onClick={() => this.handleDeckDelete(deckHash)}
                            size="small"
                        >
                            <i className="fa fa-trash-alt"/>
                        </IconButton>
                        <Button
                            variant="contained"
                            className="deck-button-private"
                            key="delete"
                            color="primary"
                            onClick={() => this.handleDeckEdit(deckHash)}
                        >{this.state.decks[key].name}<img className="deck-clan-logo" src={deckLogo} alt={this.state.decks[key].clan}/>
                        </Button>
                    </div>
                );
            }

            listItems.push(
                    <Button
                        variant="contained"
                        key="new"
                        color="primary"
                        className="menu-button"
                        onClick={this.handleDeckBuilder}
                    >New deck</Button>
            );
            listItems.push(
                    <Button
                        variant="contained"
                        className="menu-button"
                        key="back"
                        color="secondary"
                        onClick={this.handleBack}
                    >Back</Button>
            );
        }
        return (
            <div className="display-inner-container">
                <div className="menu-screen">
                    <UserAvatarConnector/>
                    {listItems}
                </div>
            </div>
        );
    }
}

export default selectRoute(withRouter(UserDecks));

import React from 'react';
import {withRouter} from "react-router-dom";
import {
    getGameHash, getOpponentHash, getUserToken
} from '../util/auth';
import * as routes from '../constants/routes';


const selectRoute = (Component) => {
    class SelectRoute extends React.Component {
        componentWillMount() {
            if (getGameHash()) {
                if (getOpponentHash()) {
                    this.props.history.push(routes.GAME);
                } else {
                    this.props.history.push(routes.GAME_LODGE);
                }
            } else if (!getUserToken()) {
                this.props.history.push(routes.LOGIN);
            }
        }

        render() {
            return (
                <Component />
            );
        }
    }

    return withRouter(SelectRoute);
};

export default selectRoute;
